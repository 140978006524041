import data from "./datafile";
import { ProjectFilter, TransformedProject } from "@/types";

export function find(filter: ProjectFilter): TransformedProject | undefined {
  return data.items.find(filter);
}
export function filter(filter: ProjectFilter): TransformedProject[] {
  return [...data.items].reverse().filter(filter);
}

const { emailData, translations, tags } = data;
export { emailData, translations, tags };
